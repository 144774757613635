import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SyncProvider } from "./ghc/context/SyncContext";
import { startSession } from "./ghc/redux/authStore";
import AppRoutes from "./ghc/routes/AppRoutes";

function App() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authUser = useSelector(state => state.auth);
  const userId = authUser.id;

  useEffect(()=>{ 

    /* remove this code below to select portal */
        localStorage.setItem("portalUrl",process.env.REACT_APP_BASE_URL);
        localStorage.setItem("portalTitle","SenseTechno");
    /* remove this code above to select portal */
    if(localStorage.portalUrl === undefined){
        navigate("/auth/select-portal");
    } else {
        if(localStorage.authUser){
            userId === undefined && dispatch(startSession())
        } else {
            navigate("/auth/login");
        }
    }
  },[])

  //[dispatch,navigate,userId,location]

  return (
    <div className="App">
        <SyncProvider>
            <AppRoutes/>
        </SyncProvider>
    </div>
  );
}

export default App;
