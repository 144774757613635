import React, { useContext, useEffect, useState } from 'react'
const SyncContext = React.createContext();


export const useSyncDevice = () =>{
    return useContext(SyncContext);
}

export const SyncProvider = (props) =>{

    const [syncingId,setSyncingId] = useState(null);

    

    return <SyncContext.Provider value={{syncingId,setSyncingId}} >{props.children} </SyncContext.Provider>
}