import { Dialog, DialogContent, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'

function AppAudioRecorder({callback}) {


    
    const [dialogStatus,setDialogStatus]        = useState(false);
    const [recordingStatus,setRecordingStatus]  = useState(false);
    const [stream,setStream]                    = useState(null);
    const [mediaRecorder,setMediaRecorder]      = useState(null)
    const [chunks,setChunks]                    = useState([]);
    const [audioUrl,setAudioUrl] = useState(null)


    const getMediaDevice = () =>{
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log("getUserMedia supported.");
            navigator.mediaDevices
              .getUserMedia(
                // constraints - only audio needed for this app
                {
                  audio: true,
                },
              )
              // Success callback
              .then((_stream) => {
                setStream(_stream)
                let _mediaRecorder = new MediaRecorder(_stream);
                setMediaRecorder(_mediaRecorder);
              })
              // Error callback
              .catch((err) => {
                console.error(`The following getUserMedia error occurred: ${err}`);
              });
        } else {
            console.log("getUserMedia not supported on your browser!");
        }
    }

    useEffect(()=>{
        if(recordingStatus){  // Recording Started
            if(mediaRecorder){
                setChunks([]);
                setAudioUrl(null);
                mediaRecorder.start();
            } else {
                getMediaDevice();
            }
        } else{ // Recording Stopped
            mediaRecorder?.stop()
            stream?.getTracks().forEach(function(track) {
                track.stop();
            });
            setMediaRecorder(null);
            setStream(null);
        }
    },[recordingStatus,mediaRecorder])



    useEffect(()=>{
        if(mediaRecorder){
            mediaRecorder.ondataavailable = (e) => {
                //let data  = [...chunks,e.data];
                let _chunks = [...chunks];
                _chunks.push(e.data)
                //console.log("here",data);
                setChunks(_chunks);
            }
        }
    },[mediaRecorder])



    const startRecording = () =>{
        setRecordingStatus(true);
    }

    const stopRecording = () =>{
        setRecordingStatus(false);
    }

    useEffect(()=>{
        if(chunks.length > 0){
            const blob = new Blob(chunks, { type: "audio/mp3; codecs=opus" });
            const _audioUrl = window.URL.createObjectURL(blob);
            setAudioUrl(_audioUrl)
        }
        
    },[chunks])


    const handleDialogClose = () =>{
        setDialogStatus(false);
        setMediaRecorder(null);
    }

    const upload = () =>{
        const blob = new Blob(chunks, { type: "audio/mp3; codecs=opus" });
        callback(blob);
        handleDialogClose();
    }


    return (
    <>
        <button className="btn btn-outline-primary" onClick={()=>setDialogStatus(true)} >Record Audio</button>
        <Dialog
            open={dialogStatus}
            keepMounted
            onClose={handleDialogClose}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogContent>
                <div className="text-center">
                    {recordingStatus === false ?
                        <button className="btn btn-danger" onClick={startRecording} >Start Recording</button>
                        :
                        <div className="mt-3">
                            <h3 className="mb-3">Recording Audio...</h3>
                            <LinearProgress color="warning" />
                            <button className="btn btn-danger mt-3" onClick={stopRecording} >Stop Recording</button>
                        </div>
                    }
                </div>
                {audioUrl !== null &&
                    <div className="mt-5 text-center">
                        <div>
                            <audio controls>
                                <source src={audioUrl} />
                            </audio>
                        </div>
                        <div className="mt-2 text-center">
                            <button className="btn btn-primary" onClick={upload} >Upload </button>
                        </div>
                    </div>
                }
            </DialogContent>
        </Dialog>
    </>
    )
}

export default AppAudioRecorder