import React, { useEffect, useRef, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout';
import AWS from 'aws-sdk'
import api from "../../../../api/api";
import AppAudioRecorder from '../components/AppAudioRecorder';

AWS.config.update({
    accessKeyId:process.env.REACT_APP_AWS_CLIENTID,
    secretAccessKey:process.env.REACT_APP_AWS_SECRETKEY,
    region:process.env.REACT_APP_AWS_REGION
})

const polly = new AWS.Polly();

function AwsTTSPage() {
    const [message,setMessage]      =   useState("");
    const [audioFile,setAudioFile]  =   useState(null);
    const [audioBase64,setAudioBase64]    =   useState(null)
    const convertTextToSpeach = () =>{
        // ref https://docs.aws.amazon.com/polly/latest/dg/API_SynthesizeSpeech.html
        polly.synthesizeSpeech({
            Text:message,
            OutputFormat:"mp3",
            VoiceId:'Kajal',
            LanguageCode:"hi-IN",
            Engine:"neural",
            //SpeechMarkTypes:["sentence"]
            /*
            Text:message,
            OutputFormat:"json", 
            VoiceId:'Kajal',
            LanguageCode:"hi-IN",
            Engine:"neural",
            SpeechMarkTypes:["sentence","viseme"]
            */
        },(error,data)=>{
            if(error){
                console.log(error)
            } else {
                console.log(data);
                setAudioFile(data);
            }
        })
    }


    useEffect(()=>{
        if(audioFile){
            let audioArrayBuffer = audioFile.AudioStream.buffer;
            let superBuffer = new Blob([audioArrayBuffer], {type:audioFile.ContentType});
            var reader = new FileReader();
            reader.readAsDataURL(superBuffer);
            reader.onloadend = function () {
                let _base64 = reader.result;
                setAudioBase64(_base64);
                var audio = new Audio(_base64);
                audio.play();
                
            };
        }
    },[audioFile])


    const  DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }



    const uploadFile = () =>{
        console.log("here");
        let data = new FormData();
        data.append('file', DataURIToBlob(audioBase64), 'audio.mp3');

        api.post("upload/track",data).then( (res)=>{
            if(!res.data.error){
                //AppAlert(res.data.msg);
                //onSuccess();
                console.log("here");
            } else {
                console.log("error");
            }
        }).catch( (error) => {
            console.log(error.msg,"error")
        }).finally(()=>{
            
            
        })

    }

    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-12">
                        <div className="input-group mb-3">
                            <textarea value={message} onChange={(e)=>setMessage(e.target.value)} className="form-control" placeholder="Your Message Here" />
                            <button className="btn btn-outline-success" type="button" onClick={convertTextToSpeach} >Convert</button>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {audioFile &&  audioBase64 &&
                            <div className="d-flex justify-content-center">
                                <audio controls>
                                    <source src={audioBase64} />
                                </audio>
                                <div>
                                    <a download="audo.mp3" href={audioBase64} className="btn btn-primary">Download</a>
                                    <button className="btn btn-outline-primary" onClick={uploadFile} >Upload</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <AppAudioRecorder/>
            </div>
        </AppLayout>
    )
}

export default AwsTTSPage


// ******************* Learning Standard ***************************************
function AwsTTSPageWithObjectUrl() {
    const audioRef = useRef()
    const [message,setMessage]      =   useState("");
    const [audioFile,setAudioFile]  =   useState(null);
    const [audioURL,setAudioUrl]    =   useState(null)

    const convertTextToSpeach = () =>{

        // ref https://docs.aws.amazon.com/polly/latest/dg/API_SynthesizeSpeech.html
        polly.synthesizeSpeech({
            Text:message,
            OutputFormat:"mp3",
            VoiceId:'Kajal',
            LanguageCode:"hi-IN",
            Engine:"neural",
            //SpeechMarkTypes:["sentence"]
        },(error,data)=>{
            if(error){
                console.log(error)
            } else {
                console.log(data);
                setAudioFile(data);
            }
        })
    }


    useEffect(()=>{
        if(audioFile){
            const audioArrayBuffer = audioFile.AudioStream.buffer;
            let _audioURL = URL.createObjectURL(new Blob([audioArrayBuffer],{type:audioFile.ContentType}))
            setAudioUrl(_audioURL)
            let audio = audioRef.current;
            audio.src = _audioURL;
            audio.play();
        }
    },[audioFile])

    const downloadAudio = () =>{
        if(audioFile){
            const audioArrayBuffer = audioFile.AudioStream.buffer;
            let _audioURL = URL.createObjectURL(new Blob([audioArrayBuffer],{type:audioFile.ContentType}))
            const a = document.createElement('a');
            a.href = _audioURL;
            a.download = "audio.mp3";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(_audioURL)

        }
    }

    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-5">

                    <div className="col-md-12">
                        <div className="input-group mb-3">
                            <textarea value={message} onChange={(e)=>setMessage(e.target.value)} className="form-control" placeholder="Your Message Here" />
                            <button className="btn btn-outline-success" type="button" onClick={convertTextToSpeach} >Convert</button>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <audio ref={audioRef} />
                        {audioFile && 
                            <button className="btn btn-primary" onClick={downloadAudio}>Download Audio</button>
                        }
                        
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

